<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput label="Наименование" type="text" :required="true" :value="this.formData.contractor_name"
          @change_input="(value) => onChange('contractor_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Полное ФИО Директора" type="text" :value="this.formData.director_fio"
          @change_input="(value) => onChange('director_fio', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Юридическое наименование" type="text" :required="true" :value="this.formData.legal_name"
          @change_input="(value) => onChange('legal_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="ГУИД" type="text" :value="this.formData.guid"
          @change_input="(value) => onChange('guid', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="БИН" type="text" :required="true" :value="this.formData.bin"
          @change_input="(value) => onChange('bin', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Адрес" type="textarea" :required="true" :value="this.formData.address"
          @change_input="(value) => onChange('address', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="cityOptions" label="Город" :required="true" optionValueKey="city_id"
          optionLabelKey="city_name" :value="this.formData.city_id || ''"
          @change="(value) => onChange('city_id', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="taxoparkOptions" label="Таксопарк" :required="true" optionValueKey="taxopark_id"
          optionLabelKey="taxopark_name" :value="this.formData.taxopark_id || ''"
          @change="(value) => onChange('taxopark_id', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="contractorTypeOptions" label="Тип подрядчика" :required="true"
          optionValueKey="contractor_type_id" optionLabelKey="contractor_type_name"
          :value="this.formData.contractor_type_id || ''" @change="(value) => onChange('contractor_type_id', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="contragentTypeOptions" label="Тип контрагента" optionValueKey="contragent_type_id"
          optionLabelKey="contragent_type_name" :value="this.formData.contragent_type_id || ''"
          @change="(value) => onChange('contragent_type_id', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Контакты" type="textarea" :value="this.formData.contacts"
          @change_input="(value) => onChange('contacts', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="ИИК" type="text" :value="this.formData.iik"
          @change_input="(value) => onChange('iik', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Наименование банка" type="text" :value="this.formData.account_name"
          @change_input="(value) => onChange('account_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="БИК" type="text" :value="this.formData.bik"
          @change_input="(value) => onChange('bik', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="КБе" type="number" :value="this.formData.kbe"
          @change_input="(value) => onChange('kbe', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Текст для договора" type="textarea" :value="this.formData.contract_text"
          @change_input="(value) => onChange('contract_text', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Лимит" type="number" :value="this.formData.contractor_limit"
          @change_input="(value) => onChange('contractor_limit', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="&#8470; договора присоединения" type="number" :value="this.formData.accession_contract_num"
          @change_input="(value) => onChange('accession_contract_num', value)" />
      </div>

      <div>
        <span>Дата договора присоединения</span>
        <div class="form__item">
          <CustomDate :fullWidth="true" placeholder="Дата договора присоединения"
            :value="this.formData.accession_contract_date" @change_date="(value) => onChange('accession_contract_date', value)" />
        </div>
      </div>

      <div class="form__item">
        <CustomInput label="Телефон" type="text" :required="true" :value="this.formData.phone"
          @change_input="(value) => onChange('phone', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Email" type="email" :required="true" :value="this.formData.email"
          @change_input="(value) => onChange('email', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Код (для присвоения номера спецификации)" type="text" :value="this.formData.contractor_code"
          @change_input="(value) => onChange('contractor_code', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="contractorMainTypeOptions" label="Подрядчик" optionValueKey="contractor_main_id"
          optionLabelKey="contractor_main_name" :value="this.formData.contractor_main_id || ''"
          @change="(value) => onChange('contractor_main_id', value)" />
      </div>

      <div>
        <span>Файл договора присоединения</span>
        <div class="form__item">
          <CustomUploader :value="this.formData?.document" :on-change="(value) => this.onChange('document', value)"
            label="Файл договора присоединения" />
        </div>

        <div class="form__item">
          <CustomCheckbox label="Подписан договор присоединения" :value="this.formData.is_signed_accession_contract"
            :binary="true" @change="(value) => onChange('is_signed_accession_contract', value)" />
        </div>
      </div>

      <!-- Пустышка див чтобы кнопки были справа -->
      <div></div> 
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomUploader from "@/components/form/CustomUploader";
import { getCity, getTaxopark, getContractorType, getContragentType, getContractorMainType } from "@/views/handbook/contractors/services";

export default {
  name: "add-handbook-contractor",
  components: { CustomInput, CustomSelect, CustomModalFooter, CustomDate, CustomCheckbox, CustomUploader },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        contractor_name: this.data?.body?.contractor_name || '',
        director_fio: this.data?.body?.director_fio_myspace || '', 
        legal_name: this.data?.body?.legal_name || '',
        guid: this.data?.body?.guid || '',
        bin: this.data?.body?.bin || '',
        address: this.data?.body?.address || '',
        city_id: this.data?.body?.city_id || '',
        taxopark_id: this.data?.body?.taxopark_id || '',
        contractor_type_id: this.data?.body?.contractor_type_id || '',
        contragent_type_id: this.data?.body?.contragent_type_id || '',
        contacts: this.data?.body?.contacts || '',
        iik: this.data?.body?.iik || '',
        account_name: this.data?.body?.account_name || '',
        bik: this.data?.body?.bik || '',
        kbe: this.data?.body?.kbe || '',
        contract_text: this.data?.body?.contract_text || '',
        contractor_limit: this.data?.body?.contractor_limit || '',
        accession_contract_num: this.data?.body?.accession_contract_num || '',
        accession_contract_date: this.data?.body?.accession_contract_date || '',
        document: '',
        is_signed_accession_contract: this.data?.body?.is_signed_accession_contract || 0,
        phone: this.data?.body?.phone || '',
        email: this.data?.body?.email || '',
        contractor_code: this.data?.body?.contractor_code || '',
        contractor_main_id: this.data?.body?.contractor_main_id || '',
      },
      cityOptions: [],  // опции для селекта городов
      taxoparkOptions: [],
      contractorTypeOptions: [],
      contragentTypeOptions: [],
      contractorMainTypeOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchCities() {
      this.cityOptions = await getCity();
    },
    async fetchTaxoparks() {
      this.taxoparkOptions = await getTaxopark();
    },
    async fetchContractorType() {
      this.contractorTypeOptions = await getContractorType();
    },
    async fetchContragentType() {
      this.contragentTypeOptions = await getContragentType();
    },
    async fetchContractorMainType() {
      this.contractorMainTypeOptions = await getContractorMainType();
    },
  },
  mounted() {
    this.fetchCities();
    // Когда данные загрузятся, выбираем первый город
    this.$watch('cityOptions', (newOptions) => {
      if (newOptions.length && !this.formData.city_id) {
        this.formData.city_id = newOptions[0].city_id; // Выбираем первый элемент
      }
    });
    this.fetchTaxoparks();
    this.$watch('taxoparkOptions', (newOptions) => {
      if (newOptions.length && !this.formData.taxopark_id) {
        this.formData.taxopark_id = newOptions[0].taxopark_id;
      }
    });
    this.fetchContractorType();
    this.fetchContragentType();
    this.fetchContractorMainType();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 6fr 6fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 12fr;
  }

  .form__item {
    flex: 1 1 45%;
    /* Каждый элемент занимает примерно 45% ширины */
  }
}
</style>