<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomCheckbox label="Запрет на создание автоматических платежей"
          :value="this.formData.is_not_create_auto_payment"
          @change="(value) => onChange('is_not_create_auto_payment', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Наименование" type="text" :required="true" :value="this.formData.provider_name"
          @change_input="(value) => onChange('provider_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Маржа материала" type="text" :value="this.formData.material_marzha"
          @change_input="(value) => onChange('material_marzha', value)" />
      </div>

      <div class="form__item">
        <CustomCheckbox label="Плательщик НДС" :value="this.formData.is_nds" :binary="true"
          @change="(value) => onChange('is_nds', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Полное ФИО Директора" type="text" :value="this.formData.director_fio"
          @change_input="(value) => onChange('director_fio', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="countryOptions" label="Страна резидентства" :required="true"
          optionValueKey="contragent_country_id" optionLabelKey="country_name"
          :value="this.formData.contragent_country_id || ''"
          @change="(value) => onChange('contragent_country_id', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Телефон" type="text" :required="true" :value="this.formData.phone"
          @change_input="(value) => onChange('phone', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Адрес" type="textarea" :required="true" :value="this.formData.address"
          @change_input="(value) => onChange('address', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="cityOptions" label="Город" :required="true" optionValueKey="city_id"
          optionLabelKey="city_name" :value="this.formData.city_id || ''"
          @change="(value) => onChange('city_id', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="contragentTypeOptions" label="Тип контрагента" optionValueKey="contragent_type_id"
          optionLabelKey="contragent_type_name" :value="this.formData.contragent_type_id || ''"
          @change="(value) => onChange('contragent_type_id', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="providerTypeOptions" label="Тип поставщика" optionValueKey="provider_type_id"
          optionLabelKey="provider_type_name" :value="this.formData.provider_type_id || ''"
          @change="(value) => onChange('provider_type_id', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="ГУИД" type="text" :value="this.formData.guid"
          @change_input="(value) => onChange('guid', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="БИН" type="text" :required="true" :value="this.formData.bin"
          @change_input="(value) => onChange('bin', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="ИИК" type="text" :value="this.formData.iik"
          @change_input="(value) => onChange('iik', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Наименование банка" type="text" :value="this.formData.bank_name"
          @change_input="(value) => onChange('bank_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="БИК" type="text" :value="this.formData.bik"
          @change_input="(value) => onChange('bik', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="КБе" type="number" :value="this.formData.kbe"
          @change_input="(value) => onChange('kbe', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Текст для договора" type="textarea" :value="this.formData.contract_text"
          @change_input="(value) => onChange('contract_text', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Комментарий" type="textarea" :value="this.formData.comment"
          @change_input="(value) => onChange('comment', value)" />
      </div>

      <div class="form__item">
        <CustomCheckbox label="Показывать цену до скидки" :value="this.formData.is_show_old_price" :binary="true"
          @change="(value) => onChange('is_show_old_price', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Email" type="email" :value="this.formData.provider_email"
          @change_input="(value) => onChange('provider_email', value)" />
      </div>

      <div class="form__item">
        <CustomCheckbox label="Подписка на рассылку" :value="this.formData.is_subscribe" :binary="true"
          @change="(value) => onChange('is_subscribe', value)" />
      </div>

      <div class="form__item">
        <CustomCheckbox label="Автоматическая отправка заявок по ГПР" :value="this.formData.is_auto_gpr" :binary="true"
          @change="(value) => onChange('is_auto_gpr', value)" />
      </div>

      <div class="form__item">
        <CustomCheckbox label="Доставка в субботу" :value="this.formData.is_saturday_delivery" :binary="true"
          @change="(value) => onChange('is_saturday_delivery', value)" />
      </div>

      <!-- Пустышка див чтобы кнопки были справа -->
      <div></div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import { getCity, getProviderCountry, getContragentType, getProviderType } from "@/views/handbook/Providers/services";

export default {
  name: "add-handbook-provider",
  components: { CustomCheckbox, CustomModalFooter, CustomInput, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        is_not_create_auto_payment: this.data?.body?.is_not_create_auto_payment || false,
        provider_name: this.data?.body?.provider_name || '',
        material_marzha: this.data?.body?.material_marzha || '',
        is_nds: this.data?.body?.is_nds || 0,
        director_fio: this.data?.body?.director_fio || '',
        guid: this.data?.body?.guid || '',
        bin: this.data?.body?.bin || '',
        address: this.data?.body?.address || '',
        city_id: this.data?.body?.city_id || '',
        provider_type_id: this.data?.body?.provider_type_id || '',
        contragent_type_id: this.data?.body?.contragent_type_id || '',
        iik: this.data?.body?.iik || '',
        bank_name: this.data?.body?.bank_name || '',
        bik: this.data?.body?.bik || '',
        kbe: this.data?.body?.kbe || '',
        contract_text: this.data?.body?.contract_text || '',
        phone: this.data?.body?.phone || '',
        provider_email: this.data?.body?.provider_email || '',
        comment: this.data?.body?.comment || '',
        is_show_old_price: this.data?.body?.is_show_old_price || 0,
        is_subscribe: this.data?.body?.is_subscribe || 0,
        is_auto_gpr: this.data?.body?.is_auto_gpr || 0,
        is_saturday_delivery: this.data?.body?.is_saturday_delivery || 0,

      },
      cityOptions: [],  // опции для селекта городов
      countryOptions: [],  
      contragentTypeOptions: [], 
      providerTypeOptions: [], 
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchCities() {
      this.cityOptions = await getCity();
    },
    async fetchCountries() {
      this.countryOptions = await getProviderCountry();
    },
    async fetchContragentType() {
      this.contragentTypeOptions = await getContragentType();
    },
    async fetchProviderType() {
      this.providerTypeOptions = await getProviderType();
    },
  },
  mounted() {
    this.fetchCities();
    // Когда данные загрузятся, выбираем первый город
    this.$watch('cityOptions', (newOptions) => {
      if (newOptions.length && !this.formData.city_id) {
        this.formData.city_id = newOptions[0].city_id; // Выбираем первый элемент
      }
    });
    this.fetchCountries();
    this.$watch('countryOptions', (newOptions) => {
      if (newOptions.length && !this.formData.contragent_country_id) {
        this.formData.contragent_country_id = newOptions[0].contragent_country_id;
      }
    });
    this.fetchContragentType();
    this.fetchProviderType();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 6fr 6fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 12fr;
  }

  .form__item {
    flex: 1 1 45%;
    /* Каждый элемент занимает примерно 45% ширины */
  }
}
</style>
