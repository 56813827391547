import { handbookContractorsAPI } from "@/views/handbook/contractors/api";

export const getHandbookContractors = async () => {
  try {
    const res = await handbookContractorsAPI.getList();
    return res?.data;
  } catch (e) {}
};

export const addHandbookContractor = async (body = {}) => {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (key === "document" && value?.length) {
      formData.append(key, value[0]);
    } else {
      formData.append(key, value);
    }
  });

  try {
    const res = await handbookContractorsAPI.addContractor(formData);
    return res?.data;
  } catch (e) {}
};

export const updateHandbookContractor = async (contractor_id, body = {}) => {
  const formData = new FormData();

  Object.keys(body).map((key) => {
    if (key === "document" && body.document?.length) {
      formData.append(key, body.document[0]);
    } else formData.append(key, body[key]);
  });
  try {
    const res = await handbookContractorsAPI.updateContractor(
      contractor_id,
      formData
    );
    return res?.data;
  } catch (e) {}
};
export const deleteHandbookContractor = async (contractor_id) => {
  try {
    const res = await handbookContractorsAPI.deleteContractor(contractor_id);
    return res?.data;
  } catch (e) {}
};
export const getCity = async () => {
  try {
    const res = await handbookContractorsAPI.getCity();
    return res?.data;
  } catch (e) {}
};
export const getTaxopark = async () => {
  try {
    const res = await handbookContractorsAPI.getTaxopark();
    return res?.data;
  } catch (e) {}
};
export const getContractorType = async () => {
  try {
    const res = await handbookContractorsAPI.getContractorType();
    return res?.data;
  } catch (e) {}
};
export const getContragentType = async () => {
  try {
    const res = await handbookContractorsAPI.getContragentType();
    return res?.data;
  } catch (e) {}
};
export const getContractorMainType = async () => {
  try {
    const res = await handbookContractorsAPI.getContractorMainType();
    return res?.data;
  } catch (e) {}
};

export const contractorsHandbookColumns = [
  { name: "contractor_id", field: "ID", label: "ID", align: "center" },
  {
    name: "contractor_name",
    field: "contractor_name",
    label: "Наименование",
    align: "left",
  },
  {
    name: "director_fio",
    field: "director_fio",
    label: "ФИО директора",
    align: "left",
  },
  { name: "documents", field: "documents", label: "Документы", align: "left" },
  {
    name: "legal_name",
    field: "legal_name",
    label: "Юридическое наименование",
    align: "left",
  },
  { name: "bin", field: "bin", label: "БИН", align: "left" },
  { name: "address", field: "address", label: "Адрес", align: "left" },
  { name: "city_name", field: "city_name", label: "Город", align: "left" },
  { name: "contacts", field: "contacts", label: "Контакты", align: "left" },
  { name: "iik", field: "iik", label: "ИИК", align: "left" },
  {
    name: "account_name",
    field: "account_name",
    label: "Банковский счет",
    align: "left",
  },
  { name: "bik", field: "bik", label: "БИК", align: "left" },
  {
    name: "contract_text",
    field: "contract_text",
    label: "Текст для договора",
    align: "left",
  },
  {
    name: "accession_contract_num",
    field: "accession_contract_num",
    label: "Договор",
    align: "left",
  },
  { name: "phone", field: "phone", label: "Телефон", align: "left" },
  { name: "email", field: "email", label: "Email", align: "left" },
  {
    name: "is_send_to_1c_text",
    field: "is_send_to_1c_text",
    label: "1C",
    align: "center",
  },
  { name: "edit", field: "edit", label: "Ред.", align: "center" },
  { name: "delete", field: "delete", label: "Удалить", align: "center" },
];
