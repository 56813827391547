<template>
  <date-picker :disabled-date="(date) => this.disabledTime(date)"
               inputClass="mx-input custom-date__input" @open="this.onEvent" popup-class="popupWrapper"
               :placeholder="`${this.placeholder || 'Введите дату'} ${this.required ? '*' : ''}`"
               :class="['custom-date', {'custom-date__full': this.fullWidth}, {'custom-date__min': this.minWidthMode}]"
               v-model:value="model" @clear="clearValue"
               format="DD.MM.YYYY" :lang="langString"
               :style="width ? {minWidth: `${this.width}px`, maxWidth: `${this.width}px`} : {}">
    <template v-slot:input>
      <input :required="!!this.required"
             :value="this.value ? new Date(toValidComponentDate(this.value, true)).toLocaleDateString('ru') || '' : ''"
             @clear="clearValue"
             :placeholder="`${this.placeholder || 'Введите дату'} ${this.required ? '*' : ''}`"
             :class="['mx-input', 'custom-date__input', 'custom-date', {'custom-date__full': this.fullWidth, 'custom-date__min': this.minWidthMode, 'custom-date__error_input': this.showRequiredError}]"/>
      <span v-if="this.showRequiredError" class="custom-date__error_message">Это поле обязательно</span>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/locale/ru.es';
import {toValidComponentDate} from "@/_helpers"
import {getLocaleDate} from "@/services"

export default {
  name: "CustomDate",
  props: ["value", "placeholder", "required", "triggerValidationOnMount", "fullWidth", "minWidthMode", 'width', 'disablePast'],
  components: {DatePicker},
  data() {
    return {
      langString: 'ru-es',
    };
  },
  methods: {
    toValidComponentDate,
    getLocaleDate,
    clearValue() {
      this.$emit("change_date", "");
    },
    disabledTime(date) {
      if (this.disablePast)
        return (new Date().getTime() - 86400000) > new Date(date).getTime()
      return false
    },
    onEvent() {
      const body = document.querySelector('body')
      const bodyTop = +body.style.top.replace('px', '')
      const timeout = setTimeout(() => {
        const popup = document.querySelector('.popupWrapper')
        const top = +popup.style.top.replace('px', '') + Math.abs(bodyTop)
        popup.style.top = `${top}px`
        clearTimeout(timeout)
      }, 10)
    },
  },
  computed: {
    model: {
      get() {
        const validDate = toValidComponentDate(this.value)
        return validDate ? new Date(validDate) : ""
      },
      set(newValue) {
        this.$emit("change_date", getLocaleDate(newValue));
      },
    },
    showRequiredError() {
      return this.required && !this.value && this.triggerValidationOnMount
    }
  },
};
</script>

<style lang="scss">
$default-color: #000;
$primary-color: #000;
@import '~vue-datepicker-next/scss/index.scss';
.custom-date {
  max-width: 250px;
  min-width: 200px;
  font-size: 12px;

  &__error {
    &_input {
      border: 2px solid #C10015;

      &::placeholder {
        color: #C10015;
      }

      &:hover {
        border: 2px solid #C10015 !important;
      }

      &:focus {
        border: 2px solid #C10015 !important;
      }
    }

    &_message {
      bottom: -16px;
      font-size: 11px;
      left: 0;
      position: absolute;
      color: #C10015;
    }
  }

  &__full {
    min-width: 100%;
    width: 100%;
    max-width: initial;
  }

  &__min {
    max-width: 110px;
    min-width: 110px;
  }

  &__input:hover,
  &__input:focus
  {
    border-color: #1D1D1D;
  }
}
</style>
