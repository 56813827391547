import instance from "@/services/api";

export const handbookProvidersAPI = {
  async getList() {
    return await instance().get('/handbook/providers/read/').then(res => res?.data)
  },
  async addProvider(body) {
    return await instance().post(`/handbook/providers/add/`, body,{
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async updateProvider(provider_id, body) {
    return await instance().put(`/handbook/providers/${provider_id}/update/`, body,{
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async deleteProvider(provider_id) {
    return await instance().delete(`handbook/providers/${provider_id}/delete/`).then(res => res?.data)
  },
  async getProviderType() {
    return await instance().get(`/common/providers/type/`).then(res => res?.data)
  },
  async getProviderCountry() {
    return await instance().get(`/common/contragents/country/`).then(res => res?.data)
  },
}

