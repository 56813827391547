<template>
  <div>
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
          :options="this.steps"
          label="Шаг"
          :required="true"
          optionValueKey="step_id"
          optionLabelKey="step_name"
          :value="this.formData.step_id || ''"
          @change="(value) => onChange('step_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
          :options="this.gradeActions"
          label="Действие"
          :required="true"
          optionValueKey="activity_id"
          optionLabelKey="activity_name"
          :value="this.formData.activity_id || ''"
          @change="(value) => onChange('activity_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="Параметры (json)"
          type="textarea"
          rows="5"
          :value="this.formData.param_value_json"
          @change_input="(value) => onChange('param_value_json', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="Порядок"
          type="number"
          :value="this.formData.order_num"
          @change_input="(value) => onChange('order_num', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
          :options="this.grades"
          label="Грейды"
          optionValueKey="grade_id"
          optionLabelKey="grade_name"
          :value="this.formData.grade_ids || ''"
          @change="(value) => onChange('grade_ids', value)"
          :multiple="true"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="Комментарии"
          type="text"
          :value="this.formData.comment"
          @change_input="(value) => onChange('comment', value)"
        />
      </div>
      <div class="form__item">
        <CustomCheckbox
          label="Активный"
          :value="this.formData.is_active"
          @change="(value) => onChange('is_active', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {getGradeActions} from "@/views/handbook/gradeTimelineActions/services";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {getActivitySteps} from "@/views/handbook/stepActivity/services";
import { getGrades } from "@/views/timeline/services";

export default {
  name: "add-step-activity",
  components: {CustomCheckbox, CustomSelect, CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      gradeActions: [],
      steps: [],
      grades: [],
      formData: {
        step_id: this.data?.body?.step_id || '',
        activity_id: this.data?.body?.activity_id || '',
        comment: this.data?.body?.comment || '',
        grade_ids: this.data?.body?.grade_ids || [],
        order_num: this.data?.body?.order_num || '',
        param_value_json: this.data?.body?.param_value_json || '',
        is_active: !!this.data?.body?.is_active,
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchGradeActions() {
      const res = await getGradeActions()
      this.gradeActions = res || []
    },
    async fetchSteps() {
      const res = await getActivitySteps()
      this.steps = res || []
    },
    async getGradesData() {
      const res = await getGrades()
      if(!res) return
      this.grades = res
    },
  },
  mounted() {
    this.fetchGradeActions()
    this.fetchSteps()
    this.getGradesData()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
