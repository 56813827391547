import { handbookStepActivityAPI } from "@/views/handbook/stepActivity/api";

export const getStepActivities = async () => {
  try {
    const res = await handbookStepActivityAPI.getList();
    return res?.data;
  } catch (e) {}
};
export const addStepActivity = async (body = {}) => {
  try {
    const res = await handbookStepActivityAPI.addStepActivity(body);
    return res?.data;
  } catch (e) {}
};
export const updateStepActivity = async (step_activity_id, body = {}) => {
  try {
    const res = await handbookStepActivityAPI.updateStepActivity(
      step_activity_id,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const deleteStepActivity = async (step_activity_id) => {
  try {
    const res = await handbookStepActivityAPI.deleteStepActivity(
      step_activity_id
    );
    return res?.data;
  } catch (e) {}
};
export const getActivitySteps = async () => {
  try {
    const res = await handbookStepActivityAPI.getSteps();
    return res?.data;
  } catch (e) {}
};

export const activityStepsColumns = [
  {
    name: "step_activity_id",
    field: "step_activity_id",
    label: "ID",
    align: "center",
  },
  {
    name: "step_name",
    field: "step_name",
    label: "Шаг",
    align: "left",
  },
  {
    name: "activity_name",
    field: "activity_name",
    label: "Действие",
    align: "left",
  },
  {
    name: "order_num",
    field: "order_num",
    label: "Порядок",
    align: "left",
  },
  {
    name: "is_active",
    field: "is_active",
    label: "Активный",
    align: "center",
  },
  {
    name: "grade_list",
    field: "grade_list",
    label: "Список грейдов",
    align: "left",
  },
  {
    name: "param_value_json",
    field: "param_value_json",
    label: "Параметры (json)",
    align: "left",
  },
  {
    name: "comment",
    field: "comment",
    label: "Комментарии",
    align: "left",
  },
  {
    name: "actions",
    label: "",
    align: "center",
  },
];
