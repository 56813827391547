<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput label="Название" type="text" :required="true" :value="this.formData.techproject_developer_name"
          @change_input="(value) => onChange('techproject_developer_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Полное ФИО Директора" type="text" :value="this.formData.director_fio_myspace"
          @change_input="(value) => onChange('director_fio', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Юридическое наименование" type="text" :value="this.formData.legal_name"
          @change_input="(value) => onChange('legal_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Адрес" type="text" :required="true" :value="this.formData.address"
          @change_input="(value) => onChange('address', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="cityOptions" label="Город" :required="true" optionValueKey="city_id"
          optionLabelKey="city_name" :value="this.formData.city_id || ''"
          @change="(value) => onChange('city_id', value)" />
      </div>

      <div class="form__item">
        <CustomSelect :options="contragentTypeOptions" label="Тип контрагента" optionValueKey="contragent_type_id"
          optionLabelKey="contragent_type_name" :value="this.formData.contragent_type_id || ''"
          @change="(value) => onChange('contragent_type_id', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Телефон" type="text" :value="this.formData.phone"
          @change_input="(value) => onChange('phone', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="ГУИД" type="text" :value="this.formData.guid"
          @change_input="(value) => onChange('guid', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="БИН" type="text" :required="true" :value="this.formData.bin"
          @change_input="(value) => onChange('bin', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="БИК" type="text" :value="this.formData.bik"
          @change_input="(value) => onChange('bik', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="ИИК" type="text" :value="this.formData.iik"
          @change_input="(value) => onChange('iik', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="КБе" type="number" :value="this.formData.kbe"
          @change_input="(value) => onChange('kbe', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Наименование банка" type="text" :value="this.formData.bank_name"
          @change_input="(value) => onChange('bank_name', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Текст для договора" type="textarea" :value="this.formData.contract_text"
          @change_input="(value) => onChange('contract_text', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Email" type="email" :value="this.formData.email"
          @change_input="(value) => onChange('email', value)" />
      </div>

      <!-- Пустышка див чтобы кнопки были справа -->
      <div></div>
      <div></div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import { getCity, getContragentType } from "@/views/handbook/techprojectDevelopers/services";

export default {
  name: "add-handbook-techproject-developer",
  components: { CustomCheckbox, CustomModalFooter, CustomInput, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        techproject_developer_name: this.data?.body?.techproject_developer_name || '',
        director_fio: this.data?.body?.director_fio || '',
        guid: this.data?.body?.guid || '',
        bin: this.data?.body?.bin || '',
        address: this.data?.body?.address || '',
        city_id: this.data?.body?.city_id || '',
        contragent_type_id: this.data?.body?.contragent_type_id || '',
        iik: this.data?.body?.iik || '',
        bank_name: this.data?.body?.bank_name || '',
        bik: this.data?.body?.bik || '',
        kbe: this.data?.body?.kbe || '',
        contract_text: this.data?.body?.contract_text || '',
        phone: this.data?.body?.phone || '',
        email: this.data?.body?.email || '',
      },
      cityOptions: [],  // опции для селекта городов
      contragentTypeOptions: [], 
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchCities() {
      this.cityOptions = await getCity();
    },
    async fetchContragentType() {
      this.contragentTypeOptions = await getContragentType();
    },
  },
  mounted() {
    this.fetchCities();
    // Когда данные загрузятся, выбираем первый город
    this.$watch('cityOptions', (newOptions) => {
      if (newOptions.length && !this.formData.city_id) {
        this.formData.city_id = newOptions[0].city_id; // Выбираем первый элемент
      }
    });
    this.fetchContragentType();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 6fr 6fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 12fr;
  }

  .form__item {
    flex: 1 1 45%;
    /* Каждый элемент занимает примерно 45% ширины */
  }
}
</style>