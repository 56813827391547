<template>
  <q-linear-progress indeterminate v-if="this.isFetching" />
  <CreateDSTemplate
    cancelBtnText="Закрыть"
    :submitBtnText="this.dsData?.ds_id ? 'Сохранить' : 'Создать'"
    :disabled="this.isSubmitDisabled"
    :cancelApproveMode="this.cancelApproveMode"
    :on-submit="this.dsData?.ds_id ? this.updateDS : this.updateDS"
    :on-cancel="this.onCancel"
    :loading="this.loading"
    :label="this.dsData?.ds_type_name"
  >
    <div class="form">
      <div class="form__header">
        <div class="form__header_inputs">
          <div class="form__header_item">
            <CustomDate
              :full-width="true"
              :disable-past="true"
              placeholder="Дата"
              :value="this.formData.ds_date || ''"
              @change_date="(value) => onChange('ds_date', value)"
            />
          </div>
        </div>
      </div>

      <!-- Agreement cancel type selection -->
      <div class="form__content">
        <div class="radio_btns">
          <div class="form__content_item">
            <q-radio
              v-model="formData.agreement_cancel_type"
              :val="1"
              label="Без удержания"
            />
          </div>
          <div class="form__content_item">
            <q-radio
              v-model="formData.agreement_cancel_type"
              :val="2"
              label="Штраф"
            />
          </div>
          <div class="form__content_item">
            <q-radio
              v-model="formData.agreement_cancel_type"
              :val="3"
              label="Частично сделаны работы"
            />
          </div>
        </div>
        <!-- Показываем поле для penalty_sum, если выбран вариант 2 -->
        <div
          v-if="formData.agreement_cancel_type == 2"
          class="form__content_item"
        >
          <CustomInput
            type="number"
            label="Сумма штрафа"
            v-model="formData.penalty_sum"
          />
        </div>

        <!-- Показываем поля для penalty_sum и work_sum, если выбран вариант 3 -->
        <div
          v-if="formData.agreement_cancel_type == 3"
          class="form__content_item"
        >
          <CustomInput
            type="number"
            label="Сумма штрафа"
            v-model="formData.penalty_sum"
          />
          <CustomInput
            type="number"
            label="Сумма за выполненные работы"
            v-model="formData.work_sum"
          />
        </div>
      </div>
    </div>
  </CreateDSTemplate>
</template>

<script>
import CreateDSTemplate from "@/views/ClientRequests/remontDS/dsCreationTemplates/template";
import { createDS, updateDS, getDSData } from "@/views/ClientRequests/remontDS/services";
import CustomInput from "@/components/form/CustomInput";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomDate from "@/components/form/CustomDate";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "AgreementCancel",
  components: { CustomInput, CustomInputNumber, CustomCheckbox, CustomDate, CreateDSTemplate },
  props: ['onCancel', 'updateData', 'client_request_id', 'dsData'],
  data() {
    return {
      manualSum: false,
      isFetching: false,
      loading: false,
      isFormChanged: !!this.dsData?.ds_id,
      data: null,
      formData: {
        ds_id: this.dsData?.ds_id || null,  // Добавляем ds_id
        ds_type_id: this.dsData?.ds_type_id,
        ds_date: new Date().toLocaleDateString('ru'),
        agreement_cancel_type: this.dsData?.agreement_cancel_type,
        penalty_sum: this.dsData?.penalty_sum,
        work_sum: this.dsData?.work_sum
      },
      // data: null
    };
  },
  computed: {
    isSubmitDisabled() {
      if (!this.savedData) return false
    },
    cancelApproveMode() {
      return !this.isSubmitDisabled && this.isFormChanged
    },
  },
  methods: {
    async createDS(onMount = false) {
      if (this.formData.ds_id) return
      this.loading = true;
      try {
        const body = onMount ? { ds_type_id: this.dsData?.ds_type_id } : this.formData
        const res = await createDS(this.client_request_id, body);

        this.loading = false;
        if (res) {
          if (onMount) {
            this.formData = { ...this.formData, ...res.header, ...res.data }
          } else {
            this.updateData && this.updateData();
            this.showSucceedSnackbar();
          }
        } else {
          console.error('Failed to create DS:', res);  // Логирование ошибки
        }
      } catch (error) {
        this.loading = false;
        console.error('Error while creating DS:', error);
      }
    },
    async updateDS() {
      this.loading = true;
      const res = await updateDS(this.client_request_id, this.formData.ds_id, this.formData);
      this.loading = false;
      if (res) {
        this.updateData && this.updateData();
        this.showSucceedSnackbar();
      }
    },
    async getDS() {
      this.isFetching = true
      const res = await getDSData(this.client_request_id, this.dsData?.ds_id)
      this.isFetching = false
      if (!res) return
      this.formData = { ...this.formData, ...res.header, ...res.data }
    },
    onChange(key, value) {
      this.isFormChanged = true
      this.formData[key] = value || null
    },
  },
  mounted() {
    if (this.dsData?.ds_id)
      this.getDS()
    else
      this.createDS(true)
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar()
    return { showSucceedSnackbar }
  }
};
</script>

<style scoped lang="scss">
.form {
  &__header {
    background-color: #f5f5f5;
    padding: 15px;

    &_inputs {
      display: flex;
      gap: 20px;
    }

    &_info {
      margin-top: 20px;

      &>p {
        font-weight: 500;
        margin: 0 0 5px;
      }
    }
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      /* Используем Flexbox */
      flex-wrap: wrap;
      gap: 20px;
    }

    /* Это относится к элементам радио-кнопок */
    &_item>.q-radio {
      flex: 1 1 auto;
      /* Заставляем радио-кнопки занимать одинаковое пространство */
      min-width: 100px;
      /* Задаем минимальную ширину для каждой кнопки */
    }
  }
}

.radio_btns {
  margin-bottom: 10px;
  display: flex;
  /* Используем Flexbox */
  flex-wrap: wrap;
  gap: 20px;

  flex: 1 1 auto;
  /* Заставляем радио-кнопки занимать одинаковое пространство */
  min-width: 100px;
  /* Задаем минимальную ширину для каждой кнопки */
}
</style>
