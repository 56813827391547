<template>
  <div class="remontProjects__header">
    <q-btn
      :loading="this.loadingAccept"
      @click="acceptRemont"
      color="primary"
      size="sm"
      padding="sm"
      >Проект утвержден
    </q-btn>
  </div>
  <CustomTable
    title="Проекты ремонта"
    :rowsData="this.rowsData"
    :columns="this.remontProjectsColumns"
    :loading="isFetching"
    :custom-body="true"
    :add-btn="true"
    :on-add-click="handleAddClick"
  >
    <template #customBody="props">
      <q-tr :style="props.row.is_final ? `backgroundColor: #F5F5F5F` : {}">
        <custom-table-cell align="center">
          <Clickable @click="handleEditClick(props.row)">
            {{ props.row.project_remont_id }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.project_remont_name }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <Clickable
            :link="true"
            target="_blank"
            :href="`${FILE_URL}${props.row.project_remont_url}`"
            >Скачать
          </Clickable>
          <hr class="gapLine" />
          <CustomLoader
            small
            v-if="!!this.downloadingProject[props.row.project_remont_id]"
          />
          <Clickable
            v-else
            @click="() => this.downloadProjectTK(props.row.project_remont_id)"
            >Скачать (плюс ТК)
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="center">
          <Clickable
            v-if="props.row.visualisation_url"
            :link="true"
            target="_blank"
            :href="`${FILE_URL}${props.row.visualisation_url}`"
            >Скачать
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row?.project_remont_comment }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :only-true="true" :value="!!props.row?.is_final" />
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose
            :only-true="true"
            :value="props.row?.project_accepted === 1"
          />
          <span
            class="remontProjects__value"
            v-if="props.row?.project_accepted !== 1 && !!props.row.not_accept_date"
          >
            Проект отозвал: <br />
            {{ props.row?.not_accept_employee }}
          </span>
          <span class="remontProjects__value">{{
              props.row?.project_accepted === 1 ? props.row.project_accepted_date : props.row.not_accept_date
          }}</span>
        </custom-table-cell>
        <custom-table-cell align="center">
          <div class="remontProjects__chat_wrapper">
            <q-btn
              :icon="props.row.is_send_chat === 1 ? 'check' : props.row.is_send_chat === 2 ? 'close' : undefined"
              :disable="props.row.is_send_chat !== 0"
              :style="`backgroundColor: ${this.chatBtnsColors[props.row.is_send_chat]}`"
              size="xs"
              padding="xs"
              class="remontProjects__chat"
            >
              {{ this.chatBtns[props.row.is_send_chat] }}
              <PopupConfirm
                v-if="props.row.is_send_chat === 0"
                :loading="!!this.loadingItems[props.row.project_remont_id]"
                :approve="() => this.chatSendProject(props.row.project_remont_id)"
              />
            </q-btn>
            <q-btn
              v-if="props.row.show_refund_btn"
              size="xs"
              padding="xs"
              :class="['remontProjects__chat', 'remontProjects__red']"
            >
              Отозвать
              <PopupConfirm
                :loading="!!this.loadingItems[props.row.project_remont_id]"
                :approve="() => this.refundProject(props.row.project_remont_id)"
              />
            </q-btn>
          </div>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.fio }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.rowversion }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomIcon
            :loading="this.loadingItems[props.row.project_remont_id]"
            :approve="() => this.deleteProject(props.row.project_remont_id)"
            icon="trash"
          />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import CustomLoader from "@/components/common/CustomLoader";
import BlockItem from "@/components/common/BlockItem";
import CheckOrClose from "@/components/common/CheckOrClose";
import Clickable from "@/components/common/Clickable";
import CustomIcon from "@/components/common/CustomIcon";
import CustomImg from "@/components/common/CustomImg";
import CustomPopup from "@/components/common/CustomPopup";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import PopupConfirm from "@/components/common/PopupConfirm";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import { FILE_URL, MODAL_NAMES } from "@/constants";
import { downloadFile } from "@/services";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import {
  acceptRemontProject,
  addRemontProject,
  chatSendRemontProject,
  deleteRemontProject, refundRemontProject,
  remontProjectsColumns, updateRemontProject,
} from "@/views/ClientRequests/remontProjects/services";

export default {
  name: "remontProjectsBlock",
  components: {
    Clickable,
    CustomIcon,
    CheckOrClose,
    BlockItem, CustomImg, CustomTable, CustomTableCell,CustomLoader, CustomCheckbox, PopupConfirm, CustomPopup
  },
  props: ["data", "client_request_id"],
  data() {
    return {
      isFetching: false,
      loadingAccept: false,
      rowsData: this.data,
      loadingItems: {},
      selectedItems: {},
      downloadingProject: {},
      remontProjectsColumns,
      FILE_URL,
      chatBtns: ['Отправить в чат', 'Отправлен в чат', 'Отозван'],
      chatBtnsColors: ['#4F99C6', '#82AF6F', '#D15B47'],
    }
  },
  methods: {
    async acceptRemont() {
      this.loadingAccept = true
      const res = await acceptRemontProject(this.client_request_id)
      this.loadingAccept = false
      if (!res) return
      this.showSucceedSnackbar()
    },
    handleEditClick(formData) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.REMONT_PROJECT_EDIT, {
        title: "Редактирование проекта ремонта",
        formData,
        onSubmit: body => this.updateProject(body, formData?.project_remont_id),
      }));
    },
    handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.REMONT_PROJECT_EDIT, {
        title: "Добавление проекта ремонта",
        formData: {},
        onSubmit: this.addProject,
      }));
    },
    async addProject(body) {
      const res = await addRemontProject(this.client_request_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      return true
    },
    async updateProject(body, project_remont_id) {
      const res = await updateRemontProject(this.client_request_id, project_remont_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      return true
    },
    async deleteProject(id) {
      this.loadingItems[id] = true
      const res = await deleteRemontProject(this.client_request_id, id)
      this.loadingItems[id] = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async refundProject(id) {
      this.loadingItems[id] = true
      const res = await refundRemontProject(this.client_request_id, id)
      this.loadingItems[id] = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async chatSendProject(id) {
      this.loadingItems[id] = true
      const res = await chatSendRemontProject(this.client_request_id, id)
      this.loadingItems[id] = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async downloadProjectTK(project_remont_id) {
      const url = `/client_request/${this.client_request_id}/project_remont/${project_remont_id}/`
      this.downloadingProject[project_remont_id] = true
      await downloadFile(url, 'Проект', {}, 'pdf', 'get')
      this.downloadingProject[project_remont_id] = false
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
.remontProjects {
  &__header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
  }

  &__value {
    display: block;
  }

  &__red {
    background-color: #D15B47;
  }

  &__link {
    color: #3d74dc;
  }

  &__chat {
    color: #fff;
    white-space: nowrap;
    font-size: 10px !important;

    &_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      min-width: 120px;
    }
  }
}
.gapLine {
  margin: 12px 0;
  border: none;
  background: #b7b7b7;
  height: 1px;
}
</style>
