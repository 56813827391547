import { remontDsAPI } from "@/views/ClientRequests/remontDS/api";

const dsBlockNames = {
  OA__RemontFormDSBlock: "remontDsBlock",
};

export const dsRoomActionCodes = {
  deleted: "DELETED",
  created: "ADDED",
  edited: "EDITED",
};

const sortDSResponse = (res) => {
  try {
    const { data: roomsData, sum, wall_height, ds_info } = res;
    const rooms = roomsData?.map((item) => ({
      ...item,
      initial_action_code: item.action_code,
    }));
    let formData = { wall_height };
    let data = { ds_sum: sum?.ds_sum || null };
    if (ds_info) {
      data = { ...data, ...ds_info };
      const { ds_date, ds_type_id } = ds_info;
      formData = { ...formData, ds_date, ds_type_id };
    }
    if (sum) data = { ...data, ...sum };
    return { rooms, formData, data };
  } catch (e) {}
};
const getBlockData = (res) => {
  const data = res?.data;
  if (!data?.length) return;
  return data?.map((item) => ({
    ...item,
    component: dsBlockNames[item.grant_code],
  }));
};
export const getRemontDsData = async (client_request_id) => {
  try {
    const res = await remontDsAPI.getList(client_request_id);
    return getBlockData(res);
  } catch (e) {}
};
export const getDSTypes = async () => {
  try {
    const res = await remontDsAPI.getDSTypes();
    return res?.data;
  } catch (e) {}
};
export const getDSRooms = async (client_request_id, ds_id, body) => {
  try {
    const res = await remontDsAPI.getRooms(client_request_id, ds_id, body);
    return res?.data;
  } catch (e) {}
};
export const changeDSSum = async (client_request_id, ds_id, body) => {
  try {
    const res = await remontDsAPI.changeDSSum(client_request_id, ds_id, body);
    return getBlockData(res);
  } catch (e) {}
};
export const getDSData = async (client_request_id, ds_id) => {
  try {
    const res = await remontDsAPI.getDSData(client_request_id, ds_id);
    return res;
  } catch (e) {}
};
export const getRoomChangeDSData = async (client_request_id, ds_id) => {
  try {
    const res = await getDSData(client_request_id, ds_id);
    return sortDSResponse(res?.data);
  } catch (e) {}
};
export const createDS = async (client_request_id, body) => {
  try {
    const res = await remontDsAPI.createDS(client_request_id, body);
    return res;
  } catch (e) {}
};
export const createRoomChangeDS = async (client_request_id, body) => {
  try {
    const res = await createDS(client_request_id, body);
    return sortDSResponse(res?.data);
  } catch (e) {}
};
export const updateDS = async (client_request_id, ds_id, body) => {
  try {
    const res = await remontDsAPI.updateDS(client_request_id, ds_id, body);
    return res;
  } catch (e) {}
};
export const updateRoomChangeDS = async (client_request_id, ds_id, body) => {
  try {
    const res = await updateDS(client_request_id, ds_id, body);
    return sortDSResponse(res?.data);
  } catch (e) {}
};
export const removeDS = async (client_request_id, ds_id) => {
  try {
    const res = await remontDsAPI.removeDS(client_request_id, ds_id);
    return getBlockData(res);
  } catch (e) {}
};
export const sendToSignDS = async (client_request_id, ds_id) => {
  try {
    const res = await remontDsAPI.sendSignDS(client_request_id, ds_id);
    return getBlockData(res);
  } catch (e) {}
};
export const acceptDS = async (client_request_id, ds_id) => {
  try {
    const res = await remontDsAPI.acceptDS(client_request_id, ds_id);
    return getBlockData(res);
  } catch (e) {}
};
export const sendAcceptDS = async (client_request_id, ds_id) => {
  try {
    const res = await remontDsAPI.sendAcceptDS(client_request_id, ds_id);
    return getBlockData(res);
  } catch (e) {}
};

export const dsTypeCodes = {
  ROOM_CHANGE: "ROOM_CHANGE",
  NEUSTOIKA: "NEUSTOIKA",
  CLIENT_CHANGE: "CLIENT_CHANGE",
  AGREEMENT_CANCEL: "AGREEMENT_CANCEL",
};
export const dsTypeCodeComponent = {
  [dsTypeCodes.ROOM_CHANGE]: "roomChange",
  [dsTypeCodes.NEUSTOIKA]: "neustoika",
  [dsTypeCodes.CLIENT_CHANGE]: "clientChange",
  [dsTypeCodes.AGREEMENT_CANCEL]: "agreementCancel",
};
export const remontDsColumns = [
  {
    name: "ds_id",
    label: "ID",
    field: "ds_id",
    align: "center",
  },
  {
    name: "ds_date",
    label: "Дата",
    field: "ds_date",
    align: "center",
  },
  {
    name: "ds_type_name",
    label: "Тип ДС",
    field: "ds_type_name",
    align: "left",
  },
  {
    name: "ds_sum",
    label: "Сумма",
    field: "ds_sum",
    align: "right",
    style: "white-space: nowrap;",
  },
  {
    name: "fio",
    label: "Оператор",
    field: "fio",
    align: "left",
  },
  {
    name: "is_accept",
    label: "Статус",
    field: "is_accept",
    align: "left",
  },
  {
    name: "accept_date",
    label: "Дата утверждения",
    field: "accept_date",
    align: "left",
  },
  {
    name: "ds_document_url",
    label: "Документ",
    field: "ds_document_url",
    align: "center",
  },
  {
    name: "sign",
    label: "Подписание",
    align: "center",
  },
  {
    name: "client_dn_name",
    label: "Подпись",
    field: "client_dn_name",
    align: "left",
  },
  {
    name: "ds_document_name",
    label: "Документы",
    field: "ds_document_name",
    align: "center",
  },
  {
    name: "actions_add_remove",
    label: "",
    align: "center",
  },
];
