<template>
  <div class="templates">
    <CustomTable title="Поставщики" :rowsData="this.rowsData" :columns="this.providersHandbookColumns"
      :loading="isFetching" :show-search="true" :custom-body="true" :sticky-header="true" :showHeaderAdd="true"
      :on-add-click="this.handleAddClick">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.provider_id }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <Clickable @click="() => this.handleUpdateClick(props.row)">
              {{ props.row.provider_name }}
            </Clickable>
            <span v-if="props.row.is_show_old_price">
              <br>
              <em>(Показывать цену без скидки)</em>
            </span>
            <span v-else>
            </span>
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center">
            <CheckOrClose :value="props.row.is_not_create_auto_payment" />
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.material_marzha }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <CheckOrClose :value="props.row.is_nds" />
          </custom-table-cell>

          <custom-table-cell align="left">
            <span v-html="props.row.director_fio"></span>
          </custom-table-cell>

          <custom-table-cell align="left" title="Документы">
            <BlockItem>
              <CustomDropdown :onClick="this.handleClickDoc" :options="props.row.documents?.reports" title="Выгрузка" />
            </BlockItem>
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.phone }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.address }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <span v-html="props.row.city_name"></span>
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.guid }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.bin }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.kbe }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.iik }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.bank_name }} <!-- Наименование банка -->
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.contract_text }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.comment }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.provider_email }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <CheckOrClose :value="props.row.is_auto_gpr" /> <!-- Отправка заявок -->
          </custom-table-cell>

          <custom-table-cell align="left">
            <CheckOrClose :value="props.row.is_subscribe" /> <!-- Подписка на рассылку -->
          </custom-table-cell>

          <custom-table-cell align="center">
            <span v-if="props.row.is_send_to_1c_text === 'Отправлен в 1С'">
              {{ props.row.is_send_to_1c_text }}
              <br>
              <CheckOrClose :value="props.row.is_send_to_1c" />
            </span>
            <span v-else>
              {{ props.row.is_send_to_1c_text }} <br>
            </span> <br>
            <span v-html="props.row.guid"></span>
          </custom-table-cell>

          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon @click="() => this.handleUpdateClick(props.row)" icon="edit" />
          </custom-table-cell>

          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon icon="trash" :approve-mode="true" :loading="this.loading"
              :approve="() => this.onDeleteSubmit(props.row.provider_id)" />
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import BlockItem from "@/components/common/BlockItem";
import CustomDropdown from "@/components/common/CustomDropdown";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {
  getHandbookProviders,
  addHandbookProvider,
  providersHandbookColumns,
  updateHandbookProvider,
  deleteHandbookProvider
} from "@/views/handbook/Providers/services";
import CheckOrClose from "@/components/common/CheckOrClose";
import { MODAL_NAMES } from "@/constants";

export default {
  name: "HandbookProviders",
  components: { CheckOrClose, Clickable, CustomIcon, CustomTableCell, CustomTable, CustomDropdown, BlockItem },
  data() {
    return {
      providersHandbookColumns,
      rowsData: [],
      isFetching: false,
      loading: false
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getHandbookProviders()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_PROVIDER, {
        onSubmit: this.onAddSubmit,
        title: 'Добавить поставщика'
      }));
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_PROVIDER, {
        onSubmit: (body) => this.onUpdateSubmit(row.provider_id, body),
        title: 'Изменить поставщика',
        body: row
      }));
    },
    async onAddSubmit(body) {
      const res = await addHandbookProvider(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(provider_id, body) {
      const res = await updateHandbookProvider(provider_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(provider_id) {
      this.loading = true
      const res = await deleteHandbookProvider(provider_id)
      this.loading = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    handleClickDoc(doc) {
      const url = `${FILE_URL}${doc.report_url}`
      window.open(url, "_blank")
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
}
</script>