import { checkIdValid } from "@/_helpers";
import { timelineAPI } from "@/views/timeline/services/api";

export const getGrades = async () => {
  try {
    const res = await timelineAPI.getGrades();
    return res?.data
  } catch (e) {}
};
export const getGradeTimelineData = async (params = {}) => {
  if (!params?.grade_id) return;
  try {
    checkIdValid(params?.grade_id);
    const res = await timelineAPI.getGradeTimeline(params);
    const { grade_steps, coordinates } = res;
    return { nodes: grade_steps, lines: coordinates };
  } catch (e) {}
};

export const saveGradeTimelinePositionData = async (params = {}, body) => {
  if (!params?.grade_id) return;
  try {
    const res = await timelineAPI.saveGradeNodePosition(body, params);
    const { grade_steps, coordinates } = res;
    return { nodes: grade_steps, lines: coordinates };
  } catch (e) {}
};

export const getRemontTimelineData = async (params = {}) => {
  try {
    checkIdValid(params?.client_request_id);
    checkIdValid(params?.remont_id);
    const res = await timelineAPI.getRemontTimeline(params);
    const { remont_steps, coordinates, ...others } = res;
    return { nodes: remont_steps, lines: coordinates, ...others };
  } catch (e) {}
};
export const isTimelineHaveCheckAccess = async () => {
  try {
    const res = await timelineAPI.isHaveCheckAccess();
    return res.has_access
  } catch (e) {}
};
export const changeTimelineActive = async (body, params) => {
  try {
    const res = await timelineAPI.changeActive(body, params);
    const { remont_steps, coordinates, ...others } = res;
    return { nodes: remont_steps, lines: coordinates, ...others };
  } catch (e) {}
};
export const remontTimelineCheck = async (params = {}) => {
  try {
    checkIdValid(params?.client_request_id);
    checkIdValid(params?.remont_id);
    const res = await timelineAPI.remontStepCheck(params);
    const { remont_steps, coordinates } = res;
    return { nodes: remont_steps, lines: coordinates };
  } catch (e) {}
};
export const getStepNotIncludedRequests = async (grade_step_id) => {
  try {
    const res = await timelineAPI.getNotIncludedRequests(grade_step_id);
    return res?.data
  } catch (e) {}
};
export const addStepToRemont = async (grade_step_id, clientRequestIds) => {
  const body = {client_request_ids: clientRequestIds}
  try {
    const res = await timelineAPI.addStepToRemont(grade_step_id, body);
    return res?.data
  } catch (e) {}
};

export const handbookTitles = {
  ['worksets']: 'Конструктивы',
  ['notice-templates']: 'Шаблоны уведомлений',
  ['providers']: 'Поставщики',
  ['handbook-contractors']: 'Подрядчики',
  ['techproject-developers']: 'Разработчики тех проекта (Контрагент)',
  ['providers-agreement']: 'Договор поставщиков',
  ['documents']: 'Файлы организации',
  ['service-masters']: 'Мастера по услугам',
  ['mobile-apps']: 'Версии мобильного приложения',
  ['grade-actions']: 'Список действий',
  ['step-activities']: 'Действия в шагах грейда',
  ['timeline-steps']: 'Шаги',
  ['timeline-step-conditions']: 'Условия шага',
  ['grade-timeline-steps']: 'Шаги грейда',
}
