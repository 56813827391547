<template>
    <q-linear-progress indeterminate v-if="this.isFetching" />
    <CreateDSTemplate cancelBtnText="Закрыть" :submitBtnText="this.dsData?.ds_id ? 'Сохранить' : 'Создать'"
        :disabled="this.isSubmitDisabled" :cancelApproveMode="this.cancelApproveMode"
        :on-submit="this.dsData?.ds_id ? this.updateDS : this.updateDS" :on-cancel="this.onCancel"
        :loading="this.loading" :label="this.dsData?.ds_type_name">
        <div class="form">
            <div class="form__header">
                <div class="form__header_inputs">
                    <div class="form__header_item">
                        <CustomDate :full-width="true" :disable-past="true" placeholder="Дата"
                            :value="this.formData.ds_date || ''" @change_date="(value) => onChange('ds_date', value)" />
                    </div>
                    <div class="form__header_item">
                        <CustomCheckbox label="Сумма ДС вручную" v-model="this.formData.is_zero_sum"
                            :value="formData.is_zero_sum" :true-value="1" :false-value="0" @change="onManualSumChange"
                            @change_input="(value) => onChange('is_zero_sum', value)" />
                    </div>

                    <div class="form__header_item manualDsSum">
                        <div class="form__header_item">
                            <CustomInput :autoMinWidth="true" label="Сумма ДС" type="number"
                                v-model="this.formData.ds_sum" :disable="!manualSum"
                                @change_input="(value) => onChange('ds_sum', value)" />
                        </div>
                        <div class="form__header_item">
                            <CustomInput :required="true" :autoMinWidth="true"
                                label="Комментарий для ДС с ручной стоимостью" type="text"
                                v-model="this.formData.zero_sum_comment" :disable="!manualSum"
                                @change_input="(value) => onChange('zero_sum_comment', value)" />
                        </div>
                    </div>
                </div>
            </div>



            <!-- this.data &&  -->
            <div v-if="formData.ds_date" class="form__header_info">
                <p>Для неустойки:</p>
            </div>

            <!-- this.data &&  -->
            <div class="form__content" v-if="formData.ds_date">
                <div class="form__content_item">
                    <CustomInput type="number" label="Количество дней неустойки" v-model="formData.neustoika_days"
                        @change_input="(value) => onChange('neustoika_days', value)" :min="0" :step="1" />
                </div>

                <div class="form__content_item neustoika-info">
                    <span v-html="formData?.neustoika_info || 'Информация отсутствует'"></span>
                </div>
            </div>

        </div>
    </CreateDSTemplate>
</template>

<script>
import CreateDSTemplate from "@/views/ClientRequests/remontDS/dsCreationTemplates/template";
import { createDS, updateDS, getDSData } from "@/views/ClientRequests/remontDS/services";
import CustomInput from "@/components/form/CustomInput";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomDate from "@/components/form/CustomDate";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
    name: "Neustoika",
    components: { CustomInput, CustomInputNumber, CustomCheckbox, CustomDate, CreateDSTemplate },
    props: ['onCancel', 'updateData', 'client_request_id', 'dsData'],
    data() {
        return {
            manualSum: false,
            isFetching: false,
            loading: false,
            isFormChanged: !!this.dsData?.ds_id,
            data: null,
            formData: {
                ds_id: this.dsData?.ds_id || null,  // Добавляем ds_id
                ds_type_id: this.dsData?.ds_type_id,
                ds_sum: this.dsData?.ds_sum,
                is_zero_sum: this.dsData?.is_zero_sum || 0,
                zero_sum_comment: this.dsData?.zero_sum_comment,
                neustoika_days: this.dsData?.neustoika_days,
                ds_date: new Date().toLocaleDateString('ru'),
            },
            neustoika_info: this.dsData?.neustoika_info,
        };
    },
    computed: {
        isSubmitDisabled() {
            if (!this.savedData) return false
        },
        cancelApproveMode() {
            return !this.isSubmitDisabled && this.isFormChanged
        },
    },
    methods: {
        async createDS(onMount = false) {
            if (this.formData.ds_id) return
            this.loading = true;
            try {
                const body = onMount ? { ds_type_id: this.dsData?.ds_type_id } : this.formData
                const res = await createDS(this.client_request_id, body);

                this.loading = false;
                if (res) {
                    if (onMount) {
                        this.formData = { ...this.formData, ...res.header, ...res.data }
                        console.log("zerosum", this.dsData?.is_zero_sum)
                    } else {
                        this.updateData && this.updateData();
                        this.showSucceedSnackbar();
                    }
                } else {
                    console.error('Failed to create DS:', res);  // Логирование ошибки
                }
            } catch (error) {
                this.loading = false;
                console.error('Error while creating DS:', error);
            }
        },
        async updateDS() {
            this.loading = true;
            console.log(this.dsData);
            const res = await updateDS(this.client_request_id, this.formData.ds_id, this.formData);
            this.loading = false;
            if (res) {
                this.updateData && this.updateData();
                this.showSucceedSnackbar();
            }
        },
        async getDS() {
            this.isFetching = true
            const res = await getDSData(this.client_request_id, this.dsData?.ds_id)
            this.isFetching = false
            if (!res) return
            this.formData = { ...this.formData, ...res.header, ...res.data }
        },
        onChange(key, value) {
            this.isFormChanged = true
            this.formData[key] = value || null
        },
        onManualSumChange(value) {
            this.manualSum = value;
            if (!this.manualSum) {
                this.formData.zero_sum_comment = '';
            }
        }
    },
    mounted() {
        if (this.dsData?.ds_id)
            this.getDS()
        else
            this.createDS(true)
    },
    setup() {
        const { showSucceedSnackbar } = useCustomSnackbar()
        return { showSucceedSnackbar }
    }
};
</script>

<style scoped lang="scss">
.form {
    &__header {
        background-color: #f5f5f5;
        padding: 15px;

        &_inputs {
            display: grid;
            grid-template-columns: 3fr 2fr 7fr; /* 3 колонки */
            gap: 20px;

            @media (max-width: 768px) {
                grid-template-columns: 12fr; /* Полная ширина для каждой колонки */
            }
        }

        &_info {
            margin-top: 20px;

            &>p {
                font-weight: 500;
                margin: 0 0 5px;
            }
        }
    }

    &__content {
        padding: 0 15px;
        margin-top: 10px;

        margin-bottom: 10px;
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 3fr 6fr 3fr;
        gap: 20px;

        @media (max-width: 768px) {
            grid-template-columns: 12fr;
        }
    }
}
.manualDsSum {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 3fr 6fr 3fr;
    gap: 20px;

    @media (max-width: 1024px) {
      grid-template-columns: 3fr 9fr;
    }
    @media (max-width: 768px) {
        // Для экранов с шириной 768px и меньше
        grid-template-columns: 6fr 6fr; // Устанавливаем две колонки по 6
    }
}
</style>