<template>
  <CustomTable
    title="Действия в шагах грейда"
    :rowsData="this.rowsData"
    :columns="this.activityStepsColumns"
    :loading="isFetching"
    :small="true"
    :show-search="true"
    :custom-body="true"
    :on-add-click="this.handleAddClick"
    :showHeaderAdd="true"
    :sticky-header="true"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <Clickable @click="() => this.handleUpdateClick(props.row)">
            {{ props.row.step_activity_id }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.step_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.activity_name }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ props.row.order_num }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :value="props.row.is_active" />
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.grade_list || '' }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.param_value_json }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.comment }}
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon
            icon="trash"
            :approve-mode="true"
            :loading="!!this.deleteLoading[props.row.step_activity_id]"
            :approve="() => this.onDeleteSubmit(props.row.step_activity_id)"
          />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {
  activityStepsColumns,
  addStepActivity,
  deleteStepActivity,
  getStepActivities,
  updateStepActivity
} from "@/views/handbook/stepActivity/services";
import CheckOrClose from "@/components/common/CheckOrClose";

export default {
  name: "StepActivities",
  data() {
    return {
      activityStepsColumns,
      rowsData: [],
      deleteLoading: {},
      isFetching: false,
    }
  },
  components: {CheckOrClose, Clickable, CustomIcon, CustomTableCell, CustomTable},
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getStepActivities()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddStepActivity, {
        onSubmit: this.onAddStepActivity,
        title: 'Добавление действия в шаге грейда',
      }));
    },
    async onAddStepActivity(body) {
      const res = await addStepActivity(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddStepActivity, {
        onSubmit: body => this.onUpdateStepActivity(row.step_activity_id, body),
        title: 'Редактировать действие в шаге грейда',
        body: row
      }));
    },
    async onUpdateStepActivity(step_activity_id, body) {
      const res = await updateStepActivity(step_activity_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(step_activity_id) {
      this.deleteLoading = {...this.deleteLoading, [step_activity_id]: true}
      const res = await deleteStepActivity(step_activity_id)
      this.deleteLoading = {...this.deleteLoading, [step_activity_id]: false}
      if (!res) return
      this.rowsData = res
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
