import instance from "@/services/api";

export const remontDsAPI = {
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/ds/read/`).then(res => res?.data)
  },
  async changeDSSum(client_request_id, ds_id, body) {
    return await instance().put(`/client_request/${client_request_id}/ds/${ds_id}/sum/change/`, body).then(res => res?.data)
  },
  async getRooms(client_request_id, ds_id, body) {
    return await instance().post(`/client_request/${client_request_id}/ds/${ds_id}/rooms/read/`, body).then(res => res?.data)
  },
  async getDSData(client_request_id, ds_id) {
    return await instance().get(`/client_request/${client_request_id}/ds/${ds_id}/`).then(res => res?.data)
  },
  async getDSTypes() {
    return await instance().get('/client_request/common/ds_types/read/').then(res => res?.data)
  },
  async createDS(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/ds/add/`, body).then(res => res?.data)
  },
  async updateDS(client_request_id, ds_id, body) {
    return await instance().put(`/client_request/${client_request_id}/ds/${ds_id}/edit/`, body).then(res => res?.data)
  },
  async removeDS(client_request_id, ds_id) {
    return await instance().delete(`/client_request/${client_request_id}/ds/${ds_id}/delete/`).then(res => res?.data)
  },
  async sendSignDS(client_request_id, ds_id) {
    return await instance().post(`/client_request/${client_request_id}/ds/${ds_id}/send_sign/`).then(res => res?.data)
  },
  async acceptDS(client_request_id, ds_id) {
    return await instance().post(`/client_request/${client_request_id}/ds/${ds_id}/accept/`).then(res => res?.data)
  },
  async sendAcceptDS(client_request_id, ds_id) {
    return await instance().post(`/client_request/${client_request_id}/ds/${ds_id}/send_kanban/`).then(res => res?.data)
  },
}