<template>
  <div class="techprojectDevelopers-page">
    <CustomTable title="Разработчики тех проекта (контрагенты)" :rowsData="rowsData"
      :columns="techprojectDevelopersColumns" :loading="isFetching" :show-search="true" :custom-body="true"
      :sticky-header="true" :showHeaderAdd="true" :on-add-click="this.handleAddClick">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.
              techproject_developer_id }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <Clickable @click="() => this.handleUpdateClick(props.row)">
              {{ props.row.techproject_developer_name }}
            </Clickable>
          </custom-table-cell>

          <custom-table-cell align="left">
            <span v-html="props.row.director_fio"></span>
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.documents }}
            <!-- ДОДЕЛАТЬ -->
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.legal_name }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <span v-html="props.row.city_name"></span>
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.address }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.bin }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.bik }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.iik }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.kbe }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.bank_name }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.contract_text }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.guid }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.phone }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.email }}
          </custom-table-cell>

          <custom-table-cell align="center">
            <span v-html="`${props.row.is_send_to_1c_text}`"></span>
          </custom-table-cell>

          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon @click="() => this.handleUpdateClick(props.row)" icon="edit" />
          </custom-table-cell>

          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon icon="trash" :approve-mode="true" :loading="this.loading"
              :approve="() => this.onDeleteSubmit(props.row.techproject_developer_id)" />
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import CheckOrClose from "@/components/common/CheckOrClose";
import { getTechprojectDevelopers, techprojectDevelopersColumns, addHandbookTechprojectDeveloper, updateHandbookTechprojectDeveloper, deleteHandbookTechprojectDeveloper } from "@/views/handbook/techprojectDevelopers/services";
import { MODAL_NAMES } from "@/constants";

export default {
  name: "TechprojectDevelopers",
  components: { CustomTable, CustomTableCell, Clickable, CheckOrClose, CustomIcon },
  data() {
    return {
      techprojectDevelopersColumns,
      rowsData: [],
      isFetching: false,
    };
  },
  methods: {
    async getData() {
      this.isFetching = true;
      const res = await getTechprojectDevelopers();
      this.isFetching = false;
      if (res) {
        this.rowsData = res;
      }
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_TECHPROJECT_DEVELOPER, {
        onSubmit: this.onAddSubmit,
        title: 'Добавить разработчика тех проекта'
      }));
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_TECHPROJECT_DEVELOPER, {
        onSubmit: (body) => this.onUpdateSubmit(row.techproject_developer_id, body),
        title: 'Изменить разработчика тех проекта',
        body: row
      }));
    },
    async onAddSubmit(body) {
      const res = await addHandbookTechprojectDeveloper(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(techproject_developer_id, body) {
      const res = await updateHandbookTechprojectDeveloper(techproject_developer_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(techproject_developer_id) {
      this.loading = true
      const res = await deleteHandbookTechprojectDeveloper(techproject_developer_id)
      this.loading = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
  },
  mounted() {
    this.getData();
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>